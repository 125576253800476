.button_up{
    position: fixed;
    border: none;
    background-color: #3772FF;
    border-radius: 100%;
    cursor: pointer;
    bottom: 60px;
    right: 15px;
    height: 36px;
    width: 36px;
}