* {
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    padding: 0;
    margin: 0;
    font-family: "Lato", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #3b3e53;
}

/*Preloader*/
.preloader {
    background: #f2f5ff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.preloader::after {
    content: "";
    display: block;
    width: 150px;
    height: 150px;
    background: url("./assets/images/preloader.svg") center center no-repeat;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.preloader-block {
    width: 100px;
    height: 100px;
    background: url("./assets/images/preloader-mini.svg") center center
        no-repeat;
}

/*HTML Typography*/
.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5 {
    margin: 0 0 30px 0;
}

.typography h1 {
    font: 800 40px/52px "Lato";
}

.typography h2 {
    font: 800 32px/38px "Lato";
}

.typography h3 {
    font: 700 24px/32px "Lato";
    margin: 0 0 20px 0;
}

.typography h4 {
    font: 700 20px/26px "Lato";
    margin: 0 0 15px 0;
}

.typography h5 {
    font: 700 18px/24px "Lato";
    margin: 0 0 10px 0;
}

.typography h6 {
    font: 700 16px/22px "Lato";
    margin: 0 0 10px 0;
}

.typography p {
    margin: 15px 0;
}

.typography b,
.typography strong {
    font-weight: 800;
}

.typography i,
.typography em {
    font-style: italic;
}

.typography a {
    color: #3772ff;
}

.typography ul {
    margin: 20px 0;
    padding: 0;
    list-style: none;
}

.typography ul li {
    margin: 6px 0;
    padding-left: 22px;
    position: relative;
}

.typography ul li:not(li > ul)::before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background: url("./assets/images/ul.svg") center center no-repeat;
    position: absolute;
    left: 0;
    top: 6px;
}

.typography ul ul {
    margin: 0;
}

.typography ol {
    margin: 20px 0;
    padding: 0;
    list-style: none;
    counter-reset: item;
}

.typography ol li {
    margin: 7px 0;
    padding-left: 30px;
    background: none;
    counter-increment: item;
}

.typography ol li:before {
    display: inline-block;
    content: counters(item, ".");
    margin-left: -25px;
    margin-right: 12px;
    padding: 7px;
    text-align: center;
    background: #edefff;
    border-radius: 3px;
    font: 700 13px/6px "Lato";
}

.typography ol ol {
    margin: 0;
}

.typography blockquote {
    margin: 20px 0;
    padding: 30px 35px;
    background: #f2f5ff;
    border-radius: 9px;
    border-left: 5px solid #3772ff;
}

.typography table {
    border: 0;
    border-collapse: separate;
    border-spacing: 0;
}

.typography table tr td,
.typography table tr th {
    padding: 15px 30px;
    border: 2px solid #eeeeee;
    border-bottom: 0;
    border-right: 0;
}

.typography table tr th {
    color: #5f6574;
    font-weight: 400;
}

.typography table tr td:last-child,
.typography table tr th:last-child {
    border-right: 2px solid #eeeeee;
}

.typography table tr:last-child td,
.typography table tr:last-child th {
    border-bottom: 2px solid #eeeeee;
}

.typography table tr:first-child th:first-child,
.typography table tr:first-child td:first-child {
    border-radius: 16px 0 0 0;
}

.typography table tr:first-child th:last-child,
.typography table tr:first-child td:last-child {
    border-radius: 0 16px 0 0;
}

.typography table tr:last-child th:first-child,
.typography table tr:last-child td:first-child {
    border-radius: 0 0 0 16px;
}

.typography table tr:last-child th:last-child,
.typography table tr:last-child td:last-child {
    border-radius: 0 0 16px 0;
}

.typography details {
    padding: 30px 40px;
    margin-bottom: 20px;
    border: 2px solid #eeeeee;
    border-radius: 16px;
}

.typography details summary {
    padding-right: 30px;
    font: 700 17px/24px 'Lato';
    cursor: pointer;
    background: url("./assets/images/details-sprite.svg#normal") right center
        no-repeat;
    background-size: 24px 24px;
    list-style: none;
}

.typography details[open] summary {
    background-image: url("./assets/images/details-sprite.svg#close");
}

.typography details summary::marker, /* Latest Chrome, Edge, Firefox */ 
.typography details summary::-webkit-details-marker /* Safari */ {
    display: none;
}

.typography details p {
    margin-bottom: 0;
}

/*Table*/
.table {
}

.table table {
    width: 100%;
    border-collapse: collapse;
}

.table table tr th {
    padding: 10px 17px;
    border-bottom: 2px solid #eeeeee;
    font: 500 15px/18px "Lato";
    color: #5f6574;
    text-align: left;
}

.table table tr td {
    padding: 17px;
    border-bottom: 1px solid #ebebeb;
}

.table table tr:last-child td {
    border-bottom: 0;
}

.table__sort {
    display: inline-block;
    padding-right: 15px;
    position: relative;
    cursor: pointer;
}

.table__desc,
.table__asc {
    width: 8px;
    height: 5px;
    position: absolute;
    top: 5px;
    right: 0;
}

.table__asc {
    top: 12px;
    right: 0;
    transform: rotate(180deg);
}

.table__desc--active,
.table__asc--active {
    fill: #3772ff;
}

.table__nowrap {
    white-space: nowrap;
}

@media (max-width: 991.98px) { 
    .typography h1 {
        font: 800 34px/46px "Lato";
    }
    
    .typography h2 {
        font: 800 28px/35px "Lato";
    }
    
    .typography h3 {
        font: 700 23px/30px "Lato";
    }
}

@media (max-width: 575.98px) {

    .typography h1 {
        font: 800 28px/38px "Lato";
    }
    
    .typography h2 {
        font: 800 24px/32px "Lato";
    }
    
    .typography h3 {
        font: 700 21px/27px "Lato";
    }

    .typography h4 {
        font: 700 19px/26px "Lato";
    }

    .typography h5 {
        font: 700 17px/22px "Lato";
    }

    .typography h6 {
        font: 700 15px/21px "Lato";
    }

    ul {
        text-align-last: left;
    }
    
    .recharts-default-legend {
        text-align: left;
    }

    .typography details summary {
        padding-right: 30px;
        font: 700 16px/24px 'Lato';
        cursor: pointer;
        background: url('./assets/images/details-sprite.svg#normal') right center no-repeat;
        background-size: 24px 24px;
        list-style: none;
    }
    
    .typography details p {
        margin-bottom: 0;
        font-size: 14px;
    }
    .typography table tr:last-child th:first-child,
    .typography table tr:last-child td:first-child {
        border-radius:  0 16px 0 0;
    }

    .typography table tr:last-child td,
    .typography table tr:last-child th {
        border-bottom:none;
    }

    .typography table tr td, .typography table tr th {
        padding: 15px 30px;
        border: 2px solid #EEEEEE;
        border-bottom: 0px solid #EEEEEE;
        border-right: 2px solid #EEEEEE;
    }

    .typography table tr:first-child th:last-child,
    .typography table tr:first-child td:last-child {
        border-radius: 0 0 0 16px;
    }

    .typography table tr:last-child td, .typography table tr:last-child th {
        border-bottom: 0px solid #EEEEEE;
        border-left: 0;
    }
    
    .typography table tr td:last-child, .typography table tr th:last-child {
        border-right: 2px solid #EEEEEE;
        border-bottom: 2px solid #EEEEEE;
    }
    
}