/* Instructions */

.instructions__wrap {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.instructions__left {
    flex-grow: 1;
}

.instructions__right {
    position: sticky;
    top: 0;
    height: 100px;
}


.instructions__menu {
    width: 100%;
    min-width: 280px;
    padding: 30px;
    background: #EDEFFF;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
}
.instructions__menu a {
    display: inline-block;
    margin-bottom: 10px;
    font: 500 15px/20px 'Lato';
    color: #3772FF;
    
}
.instructions__menu-item {
    display: inline-block;
    border-bottom: 1px solid #CFD4FA;
}
.instructions__menu a:last-child {
    margin-bottom: 0;
}


@media (max-width: 1399.98px) {  }

@media (max-width: 1199.98px) { 
    .instructions__wrap {
        flex-direction: column-reverse;
    }
    .instructions__menu {
        width: fit-content;
    }
 }

@media (max-width: 991.98px) {
   
}

@media (max-width: 767.98px) {  }

@media (max-width: 575.98px) { 
    .instructions__wrap {
        display: flex;
        flex-direction: column;
    }

    .instructions__left {
        order: 1;
    }
    
    .instructions__contents {
        max-width: 200px;
    }
}



